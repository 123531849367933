var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('page-breadcrumb',{staticClass:"mb-3",attrs:{"title":"Level Management"}})],1)],1),_c('data-create-popup',{attrs:{"title":"Create Level","default-data":_vm.default_data,"create-data-fn":_vm.createItem},on:{"create":function($event){return _vm.$refs.group_list.getList()}}}),_c('data-table-ssr',{ref:"group_list",attrs:{"id":"group_list","columns":_vm.fields,"get-list-fn":_vm.getList,"to-edit-fn":_vm.toEditPage,"paginationPosition":'top',"setCurrentPage":parseInt(_vm.$route.params.page)},scopedSlots:_vm._u([{key:"slot_cards",fn:function(ref){
var slotData = ref.slotData;
return [_c('span',{style:({color: slotData.amount_card % 3 === 0 ? 'green' : 'red'})},[_vm._v(_vm._s(slotData.amount_card))])]}},{key:"slot_avg_titles_per_item",fn:function(ref){
var slotData = ref.slotData;
return [_c('span',[_vm._v(_vm._s(slotData.items.length ? Math.round((slotData.amount_card / slotData.items.length) * 100) / 100 : ''))])]}},{key:"slot_avg_time_per_card",fn:function(ref){
var slotData = ref.slotData;
return [_c('span',[_vm._v(_vm._s(slotData.amount_card ? Math.round((slotData.playing_time / slotData.amount_card) * 100) / 100 : ''))])]}},{key:"slot_avg_cards_per_blocker",fn:function(ref){
var slotData = ref.slotData;
return [_c('span',[_vm._v(_vm._s(slotData.blocker_amount ? Math.round((slotData.amount_card / slotData.blocker_amount) * 100) / 100 : ''))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }